import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layouts Types
import AuthLayout from "./layouts/Auth";
import AuthV2Layout from "./layouts/AuthV2";
import DashboardLayout from "./layouts/Dashboard";

// Views Types
import Signin from "./views/auth/Signin";
import Forgot from "./views/auth/Forgot";
import Signout from "./views/auth/Signout";
import Home from "./views/dashboard/Home";
import Settings from "./views/dashboard/Settings";
import AdminGeneral from "./views/dashboard/admin/AdminGeneral";
import AdminUser from "./views/dashboard/admin/AdminUser";
import AdminGarage from "./views/dashboard/admin/AdminGarage";
import AdminAgency from "./views/dashboard/admin/AdminAgency";
import BrokenGlass from "./views/dashboard/statistics/BrokenGlass";
import StatisticsGeneral from "./views/dashboard/statistics/StatisticsGeneral";
import StatisticsAbeille from "./views/dashboard/statistics/StatisticsAbeille";
import StatisticsAllianz from "./views/dashboard/statistics/StatisticsAllianz";
import StatisticsAllianzOM from "./views/dashboard/statistics/StatisticsAllianzOM";
import StatisticsAxa from "./views/dashboard/statistics/StatisticsAxa";
import Selfcare from "./views/dashboard/statistics/Selfcare";
import Ega from "./views/dashboard/statistics/EGA";
import Caravaning from "./views/dashboard/statistics/Caravaning";
import Electric from "./views/dashboard/statistics/Electric";
import Ranking from "./views/dashboard/statistics/Ranking";
import StatisticsCovea from "./views/dashboard/statistics/StatisticsCovea";
import Audit from "./views/dashboard/Audit";
import Anomaly from "./views/dashboard/Anomaly";
import MissionsTable from "./views/dashboard/missions/MissionsTable";
import MissionsMap from "./views/dashboard/missions/MissionsMap";
import ReparatorPerformance from "./views/dashboard/statistics/ReparatorPerformance";
import VVR from "./views/dashboard/statistics/VVR";
import VVNR from "./views/dashboard/statistics/VVNR";
import IRSA from "./views/dashboard/statistics/IRSA";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/signin",
    exact: true,
    layout: AuthV2Layout,
    component: Signin,
  },
  {
    path: "/forgot",
    exact: true,
    layout: AuthLayout,
    component: Forgot,
  },
  {
    path: "/signout",
    exact: true,
    layout: AuthLayout,
    component: Signout,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/settings",
    exact: true,
    layout: DashboardLayout,
    component: Settings,
  },
  {
    path: "/admin-general",
    exact: true,
    layout: DashboardLayout,
    component: AdminGeneral,
  },
  {
    path: "/admin-user",
    exact: true,
    layout: DashboardLayout,
    component: AdminUser,
  },
  {
    path: "/admin-garage",
    exact: true,
    layout: DashboardLayout,
    component: AdminGarage,
  },
  {
    path: "/admin-expert",
    exact: true,
    layout: DashboardLayout,
    component: AdminAgency,
  },
  {
    path: "/broken-glass",
    exact: true,
    layout: DashboardLayout,
    component: BrokenGlass,
  },
  {
    path: "/statistics-1",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsAbeille,
  },
  {
    path: "/statistics-2",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsAllianz,
  },
  {
    path: "/statistics-AOM",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsAllianzOM,
  },
  {
    path: "/statistics-3",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsAxa,
  },
  {
    path: "/selfcare",
    exact: true,
    layout: DashboardLayout,
    component: Selfcare,
  },
  {
    path: "/ranking",
    exact: true,
    layout: DashboardLayout,
    component: Ranking,
  },
  {
    path: "/statistics-4",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsCovea,
  },
  {
    path: "/statistics-5",
    exact: true,
    layout: DashboardLayout,
    component: StatisticsGeneral,
  },
  {
    path: "/audit",
    exact: true,
    layout: DashboardLayout,
    component: Audit,
  },
  {
    path: "/anomaly",
    exact: true,
    layout: DashboardLayout,
    component: Anomaly,
  },
  {
    path: "/missions-table",
    exact: true,
    layout: DashboardLayout,
    component: MissionsTable,
  },
  {
    path: "/missions-map",
    exact: true,
    layout: DashboardLayout,
    component: MissionsMap,
  },
  {
    path: "/ega",
    exact: true,
    layout: DashboardLayout,
    component: Ega,
  },
  {
    path: "/caravaning",
    exact: true,
    layout: DashboardLayout,
    component: Caravaning,
  },
  {
    path: "/electric",
    exact: true,
    layout: DashboardLayout,
    component: Electric,
  },
  {
    path: "/statistics-garage",
    exact: true,
    layout: DashboardLayout,
    component: ReparatorPerformance,
  },
  {
    path: "/VVNR",
    exact: true,
    layout: DashboardLayout,
    component: VVNR,
  },
  {
    path: "/VVR",
    exact: true,
    layout: DashboardLayout,
    component: VVR,
  },
  {
    path: "/IRSA",
    exact: true,
    layout: DashboardLayout,
    component: IRSA,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
